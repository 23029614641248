import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { Link, useLocation, withRouter } from "react-router-dom";
import slug from "slug";
import AddPost from "../AddPost/AddPost";
import Backup from "../Backup/Backup";
import EditPost from "../EditPost/EditPost";
import List from "../List/List";
import Pagination from "../Pagination/Pagination";
import Post from "../Post/Post";
import { PostType } from "../types/post";
import { JsonBoxURL } from "../utils";
import styles from "./App.module.scss";

const limit = 10;

const App: React.FC = ({ history }: any) => {
    const { search } = useLocation();

    const params = new URLSearchParams(search);
    const page = Number(params.get("page")) || 1;

    const [searchPost, setSearch] = useState("");
    const [posts, setPosts] = useState<PostType[]>([]);

    useEffect(() => {
        fetchData();
    }, [searchPost]);

    useEffect(() => {
        params.set("page", "1");
        history.push("/");
    }, []);

    async function fetchData() {
        const response = await fetch(`${JsonBoxURL}?limit=1000`);
        const json: PostType[] = await response.json();
        setPosts(
            json.map((p) => ({ ...p, _createdOn: new Date(p._createdOn) }))
        );
    }

    const searchWords = slug(searchPost, { lower: true }).split("-");

    function filterPost({ body, title }: PostType) {
        if (!searchPost) {
            return true; // Si aucun terme de recherche n'est saisi, tous les messages sont retournés
        }

        const postWords = [
            ...slug(body, { lower: true }).split("-"),
            ...slug(title, { lower: true }).split("-"),
        ];

        return searchWords.every((s) => postWords.some((p) => p.includes(s)));
    }

    const filteredPosts = searchPost ? posts.filter(filterPost) : posts;
    const lastPostIndex = page * limit;
    const firstPostIndex = lastPostIndex - limit;
    const currentPosts = filteredPosts.slice(firstPostIndex, lastPostIndex);

    return (
        <div className={styles.wrapper}>
            <Link to="/" className={styles.title}>
                Google Adelios
            </Link>

            <Link to="/new" title="Ajouter un post" className={styles.add} />

            <Switch>
                <Route
                    path="/"
                    exact
                    render={() => (
                        <>
                            <input
                                autoFocus
                                type="text"
                                value={searchPost}
                                className={styles.input}
                                onChange={(e) =>
                                    setSearch(e.target.value.toLowerCase())
                                }
                            />
                            <List posts={currentPosts} />
                            <Backup />
                            <Pagination
                                index={page}
                                length={filteredPosts.length}
                                limit={limit}
                            />
                        </>
                    )}
                />
                <Route
                    path="/post/:id"
                    render={({ match: { params } }) => {
                        if (!posts.length) return "loading";
                        const post = posts.find((p) => p._id === params.id)!;
                        return <Post {...post} refreshList={fetchData} />;
                    }}
                />
                <Route
                    path="/new"
                    render={() => <AddPost refreshList={fetchData} />}
                />
                <Route
                    path="/edit/:id"
                    render={({ match: { params } }) => {
                        if (!posts.length) return "loading";
                        const post = posts.find((p) => p._id === params.id)!;
                        return <EditPost {...post} refreshList={fetchData} />;
                    }}
                />
            </Switch>
        </div>
    );
};

export default withRouter(App);
